import { formatAxiosErrorToPayload, getErrorString } from '@common'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { initialFilters } from '../common/constants'
import { BaseLoad, Filters, Quote, QuoteStatus, RootState } from '../common/types'
import { formatFilters, keysToCamel } from '../common/utils'

export type Bids = {
  [key: string]: {
    rejected: boolean
    carrierBid?: string
  }
}

export type MyQuotesState = {
  autoOpenBookModal: boolean
  count: number
  quotes: Quote[]
  loading: {
    quotes: boolean
    selectedLoad: boolean
    updateQuoteStatus: boolean
    quoteOrRejectLoad: boolean
    getProjectDetails: boolean
    getProjectLoads: boolean
    bulkQuoteOrRejectLoads: boolean
  }
  offset: number
  size: number
  filters: Filters
  selectedLoad: BaseLoad | null
  projectQuotes: {
    id: number
    name: string
    totalQuotes: number
    submittedQuotes: number
    rejectedQuotes: number
    startDate: string
    endDate: string
    pendingQuotes: number
    description: string
  }[]
  projectQuotesCount: number
  projectDetails: {
    id?: number
    name?: string
    referenceNumber?: string
    startDate?: string
    endDate?: string
    notes?: string
    documents?: { file: string; fileName: string; id: number }[]
  }
  projectLoads: Quote[]
  projectLoadsCount: number
}

const initialState: MyQuotesState = {
  autoOpenBookModal: false,
  count: 0,
  quotes: [],
  loading: {
    quotes: false,
    selectedLoad: false,
    updateQuoteStatus: false,
    quoteOrRejectLoad: false,
    getProjectDetails: false,
    getProjectLoads: false,
    bulkQuoteOrRejectLoads: false,
  },
  offset: 0,
  size: 20,
  filters: initialFilters,
  selectedLoad: null,
  projectQuotes: [],
  projectQuotesCount: 0,
  projectDetails: {},
  projectLoads: [],
  projectLoadsCount: 0,
}

export const getMyQuotes = createAsyncThunk(
  'myQuotes/getMyQuotes',
  async ({ statuses }: { statuses?: QuoteStatus[] }, { getState, rejectWithValue }) => {
    const { filters, size, offset } = (getState() as RootState).myQuotes
    return await api
      .get('/quotes/api/carrier-quote-status/', {
        params: {
          ...formatFilters(filters),
          limit: size,
          offset,
          ...(statuses && { statuses: statuses.join(',') }),
        },
      })
      .then(({ data }) => keysToCamel(data))
      .catch(err => rejectWithValue(formatAxiosErrorToPayload(err)))
  },
)

export const getProjectLoads = createAsyncThunk(
  'myQuotes/getProjectLoads',
  async (projectId: string | number, { rejectWithValue }) =>
    await api
      .get('/quotes/api/carrier-quote-status/', {
        params: {
          project_id: projectId,
          limit: 200,
        },
      })
      .then(({ data }) => keysToCamel(data))
      .catch(err => rejectWithValue(formatAxiosErrorToPayload(err))),
)

export const getProjectQuotes = createAsyncThunk(
  'myQuotes/getProjectQuotes',
  async (_, { getState, rejectWithValue }) => {
    const { filters, size, offset } = (getState() as RootState).myQuotes
    return await api
      .get('/loads/api/list-quote-project/', {
        params: {
          ...formatFilters(filters),
          limit: size,
          offset,
        },
      })
      .then(({ data }) => keysToCamel(data))
      .catch(err => rejectWithValue(formatAxiosErrorToPayload(err)))
  },
)

export const getProjectDetails = createAsyncThunk(
  'myQuotes/getProjectDetails',
  async (id: string, { rejectWithValue }) =>
    await api
      .get(`/loads/api/carrier-project-detail/${id}/`)
      .then(({ data }) => keysToCamel(data))
      .catch(err => rejectWithValue(formatAxiosErrorToPayload(err))),
)

export const getLatestQuote = createAsyncThunk('myQuotes/getLatestQuote', async () =>
  api
    .get('/quotes/api/carrier-quote-status/', {
      params: {
        limit: 1,
        offset: 0,
      },
    })
    .then(({ data }) => keysToCamel(data))
    .then(({ results }) => results[0] as Quote),
)

export const getLoadDetail = createAsyncThunk(
  'loads/getLoadDetail',
  async (id: number, { rejectWithValue }) =>
    api
      .get(`/loads/api/basic-load-detail/${id}/`)
      .then(({ data }) => keysToCamel(data) as BaseLoad)
      .catch(err => rejectWithValue(formatAxiosErrorToPayload(err))),
)

export const updateQuoteStatus = createAsyncThunk(
  'myQuotes/updateQuoteStatus',
  async ({ quote_id, status }: { quote_id: number; status: number }, { rejectWithValue }) =>
    api
      .patch(`/accounts/api/carrier/update-quote-status/${quote_id}/`, {
        quote_status: status,
      })
      .then(({ data }) => data)
      .catch(err => rejectWithValue(formatAxiosErrorToPayload(err))),
)

export const quoteOrRejectLoad = createAsyncThunk(
  'myQuotes/quoteOrRejectLoad',
  async ({ id, bid }: { id: number; bid?: number }, { dispatch, rejectWithValue }) =>
    api
      .post(`/quotes/api/carrier-requested-quote/${id}/`, {
        ...(bid && { carrier_bid: bid }),
        rejected: !bid,
      })
      .then(() => {
        dispatch(getMyQuotes({ statuses: ['PENDING', 'QUOTED'] }))
        return !!bid
      })
      .catch(err => rejectWithValue(formatAxiosErrorToPayload(err))),
)

export const bulkQuoteOrRejectLoads = createAsyncThunk(
  'myQuotes/bulkQuoteOrRejectLoads',
  async (quotedLoads: Bids, { getState, dispatch, rejectWithValue }) => {
    const { projectDetails } = (getState() as RootState).myQuotes

    const payload = Object.keys(quotedLoads).map(key => ({
      quote_id: parseInt(key),
      rejected: quotedLoads[key].rejected,
      carrier_bid: quotedLoads[key].carrierBid || null,
    }))

    return await api
      .post('/quotes/api/bulk-carrier-requested-quote/', { bids: payload })
      .then(() => {
        dispatch(getProjectLoads(projectDetails.id || ''))
      })
      .catch(err => rejectWithValue(formatAxiosErrorToPayload(err)))
  },
)

export const myQuotesSlice = createSlice({
  name: 'myQuotes',
  initialState,
  reducers: {
    resetSelectedLoad: state => {
      state.selectedLoad = null
    },
    setAutoOpenBookModal: (state, { payload }: PayloadAction<boolean>) => {
      state.autoOpenBookModal = payload
    },
    setOffset: (state, { payload }: PayloadAction<number>) => {
      state.offset = payload
    },
    setSize: (state, { payload }) => {
      state.size = payload
    },
    setFilters: (state, { payload }) => {
      state.filters = payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getMyQuotes.pending, state => {
        state.loading.quotes = true
      })
      .addCase(getMyQuotes.fulfilled, (state, { payload }) => {
        const { count, results } = payload
        state.quotes = results
        state.count = count
        state.loading.quotes = false
      })
      .addCase(getMyQuotes.rejected, (state, { payload }) => {
        state.loading.quotes = false
        toast.error(getErrorString(payload, 'Error getting your quotes'))
      })
      .addCase(getProjectQuotes.pending, state => {
        state.loading.quotes = true
      })
      .addCase(getProjectQuotes.fulfilled, (state, { payload }) => {
        const { count, results } = payload
        state.projectQuotes = results
        state.projectQuotesCount = count
        state.loading.quotes = false
      })
      .addCase(getProjectQuotes.rejected, (state, { payload }) => {
        state.loading.quotes = false
        toast.error(getErrorString(payload, 'Error getting project quotes'))
      })
      .addCase(getLoadDetail.pending, state => {
        state.loading.selectedLoad = true
      })
      .addCase(getLoadDetail.fulfilled, (state, { payload }) => {
        state.loading.selectedLoad = false
        state.selectedLoad = payload
      })
      .addCase(getLoadDetail.rejected, (state, { payload }) => {
        state.loading.selectedLoad = false
        state.selectedLoad = null
        toast.warn(getErrorString(payload, 'The load you requested is unavailable'))
      })
      .addCase(updateQuoteStatus.pending, state => {
        state.loading.updateQuoteStatus = true
      })
      .addCase(updateQuoteStatus.fulfilled, state => {
        state.loading.updateQuoteStatus = false
        toast.success('Your quote has been updated successfully!')
      })
      .addCase(updateQuoteStatus.rejected, (state, { payload }) => {
        state.loading.updateQuoteStatus = false
        state.selectedLoad = null
        toast.error(getErrorString(payload, 'Error updating your quote'))
      })
      .addCase(quoteOrRejectLoad.pending, state => {
        state.loading.quoteOrRejectLoad = true
      })
      .addCase(quoteOrRejectLoad.fulfilled, (state, { payload }) => {
        state.loading.quoteOrRejectLoad = false
        toast.success(`Load has been ${payload ? 'quoted' : 'rejected'} successfully!`)
      })
      .addCase(quoteOrRejectLoad.rejected, (state, { payload }) => {
        state.loading.quoteOrRejectLoad = false
        toast.error(getErrorString(payload, 'Error quoting load'))
      })
      .addCase(getProjectDetails.pending, state => {
        state.loading.getProjectDetails = true
      })
      .addCase(getProjectDetails.fulfilled, (state, { payload }) => {
        state.loading.getProjectDetails = false
        state.projectDetails = payload
      })
      .addCase(getProjectDetails.rejected, (state, { payload }) => {
        state.loading.getProjectDetails = false
        toast.error(getErrorString(payload, 'Error getting project details'))
      })
      .addCase(getProjectLoads.pending, state => {
        state.loading.getProjectLoads = true
      })
      .addCase(getProjectLoads.fulfilled, (state, { payload }) => {
        const { count, results } = payload
        state.loading.getProjectLoads = false
        state.projectLoads = results
        state.projectLoadsCount = count
      })
      .addCase(getProjectLoads.rejected, (state, { payload }) => {
        state.loading.getProjectLoads = false
        toast.error(getErrorString(payload, 'Error getting project loads'))
      })
      .addCase(bulkQuoteOrRejectLoads.pending, state => {
        state.loading.bulkQuoteOrRejectLoads = true
      })
      .addCase(bulkQuoteOrRejectLoads.fulfilled, state => {
        state.loading.bulkQuoteOrRejectLoads = false
        toast.success('Successfully quoted loads')
      })
      .addCase(bulkQuoteOrRejectLoads.rejected, (state, { payload }) => {
        state.loading.bulkQuoteOrRejectLoads = false
        toast.error(getErrorString(payload, 'Error quoting loads'))
      })
  },
})

export const { resetSelectedLoad, setAutoOpenBookModal, setOffset, setSize, setFilters } =
  myQuotesSlice.actions

export default myQuotesSlice.reducer
