import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { useEffect, useRef, useState } from 'react'
import tw from 'tailwind-styled-components'

import { getStatusBackground } from './helpers'

type Option = {
  label: string
  onClick: () => void
}

type Section = {
  title: JSX.Element
  options: Option[]
}

type StatusProps = {
  label?: string
  status?: string | null
  active?: boolean
  className?: string
  gray?: boolean
  background?: string
  dropdownOptions?: Array<Option | Section>
  showUpdateText?: boolean
  short?: boolean
  dropdownClassName?: string
  labelClassName?: string
}

type $StatusProps = { $background: string; $dropdownOptions: boolean; $isStatus: boolean }

export const Status = ({
  label = '',
  status = '',
  active = true,
  className = '',
  gray = false,
  background = '',
  dropdownOptions,
  showUpdateText = true,
  short,
  dropdownClassName,
  labelClassName,
}: StatusProps): JSX.Element => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [dropdownClass, setDropdownClass] = useState('top-full')
  const ref = useRef<HTMLDivElement>(null)
  const dropdownRef = useRef<HTMLUListElement>(null)
  const backgroundColor = getStatusBackground(status, active, gray)
  const dropdownOptionsExist = dropdownOptions && dropdownOptions.length > 0

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setDropdownOpen(false)
      }
    }

    const adjustDropdownPosition = () => {
      if (ref.current && dropdownRef.current) {
        const { top, height } = ref.current.getBoundingClientRect()
        const dropdownElementHeight = dropdownRef.current.getBoundingClientRect().height
        const windowHeight = window.innerHeight
        const elementBottom = top + height

        if (elementBottom + dropdownElementHeight < windowHeight - 100) setDropdownClass('top-full')
        else setDropdownClass(`-top-[250px]`)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    window.addEventListener('resize', adjustDropdownPosition)

    if (dropdownOpen) adjustDropdownPosition()

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      window.removeEventListener('resize', adjustDropdownPosition)
    }
  }, [dropdownOpen])

  return (
    <Container
      ref={ref}
      $background={background || backgroundColor}
      $dropdownOptions={!!dropdownOptions}
      $isStatus={!!status}
      className={className}
      data-testid='status'
      onClick={dropdownOptionsExist ? () => setDropdownOpen(!dropdownOpen) : undefined}
    >
      <StatusLabel className={labelClassName}>{label || status?.replace(/[_-]/g, ' ')}</StatusLabel>
      {dropdownOptionsExist && (
        <UpdateTextContainer className={className}>
          <span className='flex normal-case text-xs'>
            {showUpdateText && <span className='max-lg:hidden ml-2'>Update Status</span>}
            <ChevronDownIcon className='mx-1.5 w-4 h-4 stroke-2' />
          </span>
        </UpdateTextContainer>
      )}
      {dropdownOpen && dropdownOptionsExist && (
        <Dropdown
          ref={dropdownRef}
          $short={short}
          className={`${dropdownClass} ${dropdownClassName}`}
        >
          {dropdownOptions.map((item, index) =>
            'options' in item ? (
              <ul key={index}>
                <div className='flex gap-2 text-gray-400 pl-1 py-1.5 cursor-default'>
                  {item.title}
                </div>
                {item.options.map((option, index) => (
                  <MenuItemWithinSection key={index} onClick={option.onClick}>
                    {option.label}
                  </MenuItemWithinSection>
                ))}
              </ul>
            ) : (
              <MenuItem key={index} onClick={item.onClick}>
                {item.label}
              </MenuItem>
            ),
          )}
        </Dropdown>
      )}
    </Container>
  )
}

const StatusLabel = tw.span`
  px-4
  py-2
  font-semibold
  text-center
`

const UpdateTextContainer = tw.div`
  bg-dark-blue
  !text-white
  rounded-r-full
  !rounded-l-none
  p-1
  items-center
  flex
`

const Container = tw.div<$StatusProps>`
  flex
  flex-row
  items-center
  justify-center
  rounded-full
  w-fit
  whitespace-nowrap
  h-6
  relative
  ${({ $background }) => $background}
  ${({ $dropdownOptions }) => $dropdownOptions && 'cursor-pointer'}
  ${({ $isStatus }) => $isStatus && 'uppercase'}
`

const Dropdown = tw.ul<{ $short?: boolean }>`
  absolute
  bg-white
  mt-2
  p-1.5
  right-0
  rounded-md
  shadow-lg
  text-sm
  top-full
  !min-w-[200px]
  z-20
  !max-h-[576px]
  overflow-y-auto
  ${({ $short }) => $short && '!max-h-[230px]'}
`

const MenuItem = tw.li`
  hover:bg-lighter-blue
  hover:font-bold
  hover:text-brand-primary
  px-2
  py-2
  rounded-md
  text-black
  text-right
`

const MenuItemWithinSection = tw(MenuItem)`
  list-disc
  list-inside
  text-left
`
