import './Table.scss'

import { Loader } from '@components'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useExpanded, useFilters, usePagination, useTable } from 'react-table'
import tw from 'tailwind-styled-components'

import { initialFilters } from '../../common/constants'
import { getRowClassNames } from '../../common/utils'
import { InfiniteTable } from '../InfiniteTable'
import { PaginationContainer } from './PaginationContainer'
import { SearchArea } from './SearchArea'
import { TableHeader } from './TableHeader'

export const Table = ({
  columns,
  data,
  setSize,
  loading,
  emptyText,
  count,
  setOffset,
  offset,
  size,
  scrollPosition,
  // setScrollPosition,
  // // we'll need these later for searching and filtering
  // query,
  // setQuery,
  filters = initialFilters,
  setFilters = () => {},
  // filtersCount,
  // resetFilters,
  // setOrder = (value: any) => {},
  // tags = () => {},
  // orderByDisplay = {},
  // orderingHeaders = [],
  // hasFilters = true,
  renderRowSubComponent,
  tableClassName = '',
  loaderClassName = '',
  isPaginated = true,
  onRowClick,
  selectedRowId,
  noLoader,
  noFilters,
  filtersList = [],
  mobileComponent = () => null,
  type = 'table',
  hidePagination,
}: {
  columns: any
  data: Array<any>
  setSize: Function
  loading: boolean
  emptyText?: string | false
  count: number
  setOffset: Function
  offset: number
  size: number
  scrollPosition?: number
  setScrollPosition?: Function
  query?: string
  setQuery?: Function
  filters?: any
  setFilters?: Function
  filtersCount?: number
  resetFilters?: Function
  setOrder?: Function
  tags?: Function
  orderByDisplay?: any
  orderingHeaders?: Array<string>
  hasFilters?: boolean
  renderRowSubComponent?: any
  tableClassName?: string
  loaderClassName?: string
  onRowClick?: (id: number, row: any) => void
  selectedRowId?: number
  noLoader?: boolean
  noFilters?: boolean
  isPaginated?: boolean
  filtersList?: Array<string>
  mobileComponent?: any
  type?: string
  hidePagination?: boolean
}) => {
  const [showFilters, setShowFilters] = useState(false)

  const location = useLocation()

  // @ts-ignore
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, state, page, setPageSize } =
    useTable(
      {
        columns,
        data,
        // @ts-ignore
        initialState: { pageSize: size },
        // @ts-ignore
        autoResetExpanded: false,
      },
      useFilters,
      useExpanded,
      usePagination,
    )

  const [active, setActive] = useState((offset + size) / size)

  const [, setScrollTop] = useState(0)

  const container = useRef<any>()

  useEffect(() => {
    setTimeout(() => {
      if (container.current) {
        container.current.scrollTop = scrollPosition
      }
    }, 200)
  }, [])

  useEffect(() => {
    if (container.current) container.current.scrollTop = scrollPosition
  }, [scrollPosition])

  const onScroll = () => {
    setScrollTop(container.current.scrollTop)
  }

  const renderTableBody = () => (
    <tbody {...getTableBodyProps()}>
      {page.map((row: any, i: number) => {
        prepareRow(row)

        const isEven = i % 2 === 0

        const rowClassNames = getRowClassNames(isEven, row.original.id, !!onRowClick, selectedRowId)

        return (
          <React.Fragment key={i}>
            <TableRow
              // can't use pseudo classes because renderRowSubComponent renders new rows and changes their background color
              className={rowClassNames}
              {...row.getRowProps()}
              onClick={({ target }: { target: HTMLElement }) => {
                // prevent executing row click callback if a button was pressed
                if (target.closest('.load-row-button')) return

                onRowClick && onRowClick(row.original.id, row.original)
                // setScrollPosition(scrollTop)
              }}
            >
              {row.cells.map((cell: any, i: number) => (
                <td key={i} {...cell.getCellProps()} className='p-4'>
                  {cell.render('Cell')}
                </td>
              ))}
            </TableRow>
            {row.isExpanded ? renderRowSubComponent({ row, isEven }) : null}
          </React.Fragment>
        )
      })}
    </tbody>
  )

  const fetchData = () => setSize(size + 20)

  const renderDesktopView = () => (
    <div className='hidden md:flex md:flex-col flex-1 basis-auto justify-between overflow-hidden'>
      <TableWrapper $showFilters={showFilters} $tableClassName={tableClassName}>
        <div ref={container} className='table relative' onScroll={onScroll}>
          <table {...getTableProps()} className='w-full'>
            <thead>
              {headerGroups.map((headerGroup, i: number) => (
                <TableHeader key={i} headerGroup={headerGroup} />
              ))}
            </thead>
            {loading && !noLoader && <TableLoader className={loaderClassName} />}
            {page.length
              ? renderTableBody()
              : !loading && <EmptyTable emptyText={emptyText || 'No data'} />}
          </table>
        </div>
      </TableWrapper>
      {isPaginated && !hidePagination && (
        <PaginationContainer
          active={active}
          count={count}
          offset={offset}
          // @ts-ignore
          pageSize={state.pageSize}
          setActive={setActive}
          setOffset={setOffset}
          setPageSize={setPageSize}
          // setScrollPosition={setScrollPosition}
          setSize={setSize}
        />
      )}
    </div>
  )

  const renderMobileView = () => (
    <div className='block md:hidden'>
      <InfiniteTable
        count={count}
        emptyText={emptyText}
        fetchData={fetchData}
        items={data}
        loading={loading}
        targetId={`table-${location.key}`}
        type={type}
      >
        {data.map((item: any) => (
          <Item key={item.id}>{mobileComponent(item)}</Item>
        ))}
      </InfiniteTable>
    </div>
  )

  return (
    <div className='md:h-[calc(100%-68px)] h-full relative flex flex-col grow'>
      {!noFilters && (
        <SearchArea
          filters={filters}
          filtersList={filtersList}
          setFilters={setFilters}
          setShowFilters={setShowFilters}
          showFilters={showFilters}
        />
      )}
      {renderDesktopView()}
      {renderMobileView()}
    </div>
  )
}

const TableLoader = ({ className }: { className?: string }) => (
  <tbody className='z-10 absolute w-full'>
    <tr>
      <td>
        <Loader className={className} />
      </td>
    </tr>
  </tbody>
)

const EmptyTable = ({ emptyText }: { emptyText: string }) => (
  <tbody>
    <tr>
      <td className='w-full absolute text-center mt-4'>{emptyText}</td>
    </tr>
  </tbody>
)

const Item = tw.div`
  border
  border-brand-light
  rounded-lg
  mx-4
  mb-4
  max-w-screen
`

const TableWrapper = tw.div<{
  $showFilters: boolean
  $tableClassName: string
}>`
  table-wrap
  mb-14
${props => (props.$showFilters ? 'expanded' : '')} 
${props => props.$tableClassName} 
`

const TableRow = tw.tr`
  hover:bg-lighter-blue
  transition-all
`
