import {
  CatchError,
  formatAxiosErrorToPayload,
  getErrorString,
  keysToSnakeCase,
  LoadStatusUpdateData,
} from '@common'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

import { api } from '../api/api'
import { initialFilters } from '../common/constants'
import { BookedLoad, Filters, LoadDetail, PastLoad, RootState, TenderedLoad } from '../common/types'
import { formatFilters, keysToCamel, setCityCase } from '../common/utils'

export type MyLoadsState = {
  activeLoads: BookedLoad[]
  pastLoads: {
    count: number
    loads: PastLoad[]
    offset: number
    size: number
    filters: Filters
  }
  loading: {
    activeLoads: boolean
    pastLoads: boolean
    rateConPreview: boolean
    signRateCon: boolean
    tenderedLoads: boolean
    rejectingTender: boolean
    acceptingTender: boolean
    respondingToTender: boolean
    updateStatus: boolean
    uploadingDocument: boolean
  }
  rateConPreview: string | null
  selectedLoad: LoadDetail | null
  selectedLoadLoading: boolean
  tenderedLoads: {
    count: number
    loads: TenderedLoad[]
  }
}

const initialState: MyLoadsState = {
  activeLoads: [],
  pastLoads: {
    count: 0,
    loads: [],
    offset: 0,
    size: 20,
    filters: initialFilters,
  },
  loading: {
    activeLoads: false,
    pastLoads: false,
    rateConPreview: false,
    signRateCon: false,
    tenderedLoads: false,
    rejectingTender: false,
    acceptingTender: false,
    respondingToTender: false,
    updateStatus: false,
    uploadingDocument: false,
  },
  rateConPreview: null,
  selectedLoad: null,
  selectedLoadLoading: false,
  tenderedLoads: {
    count: 0,
    loads: [],
  },
}

export const addLoadDocument = createAsyncThunk(
  'myLoads/addLoadDocument',
  async (
    document: {
      loadId: number
      fileData: string
      fileType: string
    },
    { rejectWithValue },
  ) => {
    const formData = new FormData()
    formData.append('file', document?.fileData)
    formData.append('document_type', document?.fileType)

    try {
      const response = await api.post(
        `/loads/api/upload-load-document/${document.loadId}/`, // change
        formData,
      )

      return response.data
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getActiveLoads = createAsyncThunk(
  'myLoads/getActiveLoads',
  async (_, { rejectWithValue }) => {
    const response = await api
      .get('/loads/api/carrier-booked-loads/', {
        params: { ordering: '-pickup_date' },
      })
      .then(({ data }) => keysToCamel(data))
      .catch(err => rejectWithValue(formatAxiosErrorToPayload(err)))
    return response as BookedLoad[]
  },
)

export const getPastLoads = createAsyncThunk(
  'myLoads/getPastLoads',
  async (_, { getState, rejectWithValue }) => {
    const { filters, size, offset } = (getState() as RootState).myLoads.pastLoads
    const response = await api
      .post('/loads/api/external-carrier-company-historical-loads/', formatFilters(filters), {
        params: { limit: size, offset },
      })
      .then(({ data }) => keysToCamel(data))
      .then(data => ({
        ...data,
        results: data.results.map(
          (load: any) =>
            ({
              ...load,
              shipper: { ...load.shipper, city: setCityCase(load.shipper?.city) },
              consignee: { ...load.consignee, city: setCityCase(load.consignee?.city) },
            }) as PastLoad,
        ),
      }))
      .catch(err => rejectWithValue(formatAxiosErrorToPayload(err)))

    return response
  },
)

export const getLoadDetail = createAsyncThunk(
  'myloads/getLoadDetail',
  async (id: number | string, { rejectWithValue }) =>
    api
      .get(`/loads/api/basic-load-detail/${id}/`)
      .then(({ data }) => keysToCamel(data) as LoadDetail)
      .catch(err => rejectWithValue(formatAxiosErrorToPayload(err))),
)

export const getRateConPreview = createAsyncThunk(
  'myLoads/getRateConPreview',
  async (loadId: number, { rejectWithValue }) => {
    try {
      const response = await api.get(`/loads/api/rate-con-preview/${loadId}/`, {
        responseType: 'arraybuffer',
      })
      const blob = new Blob([response.data], { type: 'application/pdf' })
      return URL.createObjectURL(blob)
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const signRateCon = createAsyncThunk(
  'myLoads/signRateCon',
  async (
    {
      loadId,
      firstName,
      lastName,
      position,
    }: { firstName: string; lastName: string; position: string; loadId: number },
    { rejectWithValue },
  ) => {
    try {
      const response = await api.post(`/loads/api/rate-con-sign/${loadId}/`, {
        first_name: firstName,
        last_name: lastName,
        position,
      })
      return response.status === 200
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const getTenderedLoads = createAsyncThunk(
  'myLoads/getTenderedLoads',
  async (_, { rejectWithValue }) =>
    api
      .get('/loads/api/get-carrier-load-tenders/', {
        params: { ordering: 'expires_at', limit: 100 },
      })
      .then(({ data }) => keysToCamel(data) as { count: number; results: TenderedLoad[] })
      .catch(err => rejectWithValue(formatAxiosErrorToPayload(err))),
)

export const acceptOrRejectTenderedLoad = createAsyncThunk(
  'myLoads/acceptOrRejectTenderedLoad',
  async (
    { loadId, tenderStatus }: { loadId: number; tenderStatus: 'ACCEPTED' | 'REJECTED' },
    { rejectWithValue },
  ) => {
    try {
      const res = await api.post(`/loads/api/accept-reject-carrier-tender/${loadId}/`, {
        tender_status: tenderStatus,
      })
      return { loadId, res }
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const updateStatus = createAsyncThunk(
  'myLoads/updateStatus',
  async (data: LoadStatusUpdateData, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.post(
        `/loads/api/load-status-update/${data.loadId}/`,
        keysToSnakeCase(data),
      )
      dispatch(getLoadDetail(data.loadId))
      return keysToCamel(response.data) as {
        loadStatus: number
        newLoadStatus: string
        transitStatus: string
        eventTime: string
        notes: string
      }
    } catch (err: CatchError) {
      return rejectWithValue(formatAxiosErrorToPayload(err))
    }
  },
)

export const myLoadsSlice = createSlice({
  name: 'myLoads',
  initialState,
  reducers: {
    resetSelectedLoad: state => {
      state.selectedLoad = null
    },
    setPastLoadsOffset: (state, { payload }: PayloadAction<number>) => {
      state.pastLoads.offset = payload
    },
    setPastLoadsSize: (state, { payload }) => {
      state.pastLoads.size = payload
    },
    setPastLoadsFilters: (state, { payload }) => {
      state.pastLoads.filters = payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getActiveLoads.pending, state => {
        state.loading.activeLoads = true
      })
      .addCase(getActiveLoads.fulfilled, (state, { payload }) => {
        state.activeLoads = payload
        state.loading.activeLoads = false
      })
      .addCase(getActiveLoads.rejected, (state, { payload }) => {
        state.loading.activeLoads = false
        toast.error(getErrorString(payload, 'Error getting your loads'))
      })
      .addCase(getPastLoads.pending, state => {
        state.loading.pastLoads = true
      })
      .addCase(getPastLoads.fulfilled, (state, { payload }) => {
        const { count, results } = payload
        state.pastLoads.loads = results
        state.pastLoads.count = count
        state.loading.pastLoads = false
      })
      .addCase(getPastLoads.rejected, (state, { payload }) => {
        state.loading.pastLoads = false
        toast.error(getErrorString(payload, 'Error getting your previous loads'))
      })
      .addCase(getLoadDetail.pending, state => {
        state.selectedLoadLoading = true
      })
      .addCase(getLoadDetail.fulfilled, (state, { payload }) => {
        state.selectedLoadLoading = false
        state.selectedLoad = payload
      })
      .addCase(getLoadDetail.rejected, (state, { payload }) => {
        state.selectedLoadLoading = false
        state.selectedLoad = null
        toast.warn(
          getErrorString(
            (payload as any)?._axiosError?.response?.status === 404 ? {} : payload,
            'The load you requested is not available',
          ),
        )
      })
      .addCase(getRateConPreview.pending, state => {
        state.loading.rateConPreview = true
      })
      .addCase(getRateConPreview.fulfilled, (state, { payload }) => {
        state.rateConPreview = payload
        state.loading.rateConPreview = false
      })
      .addCase(getRateConPreview.rejected, (state, { payload }) => {
        state.loading.rateConPreview = false
        toast.error(getErrorString(payload, 'Error getting rate confirmation preview'))
      })
      .addCase(addLoadDocument.pending, state => {
        state.loading.uploadingDocument = true
      })
      .addCase(addLoadDocument.fulfilled, state => {
        state.loading.uploadingDocument = false
        toast.success('Document successfully uploaded')
      })
      .addCase(addLoadDocument.rejected, (state, { payload }) => {
        state.loading.uploadingDocument = false
        toast.error(getErrorString(payload, 'Error uploading document'))
      })
      .addCase(signRateCon.pending, state => {
        state.loading.signRateCon = true
      })
      .addCase(signRateCon.fulfilled, (state, { payload }) => {
        state.loading.signRateCon = false
        if (payload && state.selectedLoad) {
          state.selectedLoad.needsSignedRateCon = false
        }
      })
      .addCase(signRateCon.rejected, (state, { payload }) => {
        state.loading.signRateCon = false
        toast.error(getErrorString(payload, 'Error signing rate confirmation'))
      })
      .addCase(getTenderedLoads.pending, state => {
        state.loading.tenderedLoads = true
      })
      .addCase(getTenderedLoads.fulfilled, (state, { payload }) => {
        const { count, results } = payload
        state.tenderedLoads.count = count
        state.tenderedLoads.loads = results
        state.loading.tenderedLoads = false
      })
      .addCase(getTenderedLoads.rejected, (state, { payload }) => {
        state.loading.tenderedLoads = false
        toast.error(getErrorString(payload, 'Error getting your tendered loads'))
      })
      .addCase(acceptOrRejectTenderedLoad.pending, (state, { meta: { arg } }) => {
        state.loading.respondingToTender = true
        if (arg.tenderStatus === 'ACCEPTED') {
          state.loading.acceptingTender = true
        } else {
          state.loading.rejectingTender = true
        }
      })
      .addCase(acceptOrRejectTenderedLoad.fulfilled, state => {
        state.loading.respondingToTender = false
        state.loading.acceptingTender = false
        state.loading.rejectingTender = false

        toast.success('Response sent')
      })
      .addCase(acceptOrRejectTenderedLoad.rejected, (state, { payload }) => {
        state.loading.respondingToTender = false
        state.loading.acceptingTender = false
        state.loading.rejectingTender = false
        toast.error(getErrorString(payload, 'Error while accept or reject tendered load.'))
      })
      .addCase(updateStatus.pending, state => {
        state.loading.updateStatus = true
      })
      .addCase(updateStatus.fulfilled, (state, { payload }) => {
        state.loading.updateStatus = false
        toast.success(
          `Status updated to ${payload.newLoadStatus} ${
            payload.transitStatus ? `(${payload.transitStatus})` : ''
          }`,
        )
      })
      .addCase(updateStatus.rejected, (state, { payload }) => {
        state.loading.updateStatus = false
        toast.error(getErrorString(payload, 'Failed to update load status'))
      })
  },
})

export const { resetSelectedLoad, setPastLoadsOffset, setPastLoadsSize, setPastLoadsFilters } =
  myLoadsSlice.actions

export default myLoadsSlice.reducer
