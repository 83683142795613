import './Header.scss'

import { broadcastLogout } from '@common'
import { AppLogoImage } from '@components'
import { Menu } from '@headlessui/react'
import { ArrowTopRightOnSquareIcon, Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import { defaultPath, isProduction, notionUrls } from '../../common/constants'
import { useSocket } from '../../common/events'
import { trackEvent } from '../../common/tracking'
import { User } from '../../common/types'
import { formatPhone } from '../../common/utils'
import { useAppSelector } from '../../redux/store'
import Avatar from '../Avatar/Avatar'
import { useSections } from '../Sidebar'
import { ManagedTransShipperPicker } from '../Sidebar/ManagedTransShipperPicker'
import { Transition } from '../Transition'
import { CarrierCompanyPicker } from './CarrierCompanyPicker'

export const Header = () => {
  // connect to websockets as the first thing
  useSocket()

  const [isMenuOpen, setMenuOpen] = useState(false)
  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  const outer = useRef() as React.MutableRefObject<HTMLInputElement>
  const user = useAppSelector(state => state.user.user)
  const companyDetail = useAppSelector(state => state.company.companyDetail)
  const sections = useSections()
  const companyIdentifier = useMemo(() => {
    if (companyDetail.mcNumber) return { label: 'MC Number', value: companyDetail.mcNumber }
    if (companyDetail.dotNumber) return { label: 'DOT Number', value: companyDetail.dotNumber }
    // this will likely never happen, but just in case
    return { label: 'Company Name', value: companyDetail.name }
  }, [companyDetail])

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])

  const handleClick = (e: { target: any }) => {
    if (outer.current && outer.current.contains(e.target)) return
    setDropdownOpen(false)
  }

  const openFAQ = () => {
    trackEvent('Clicked FAQ link')
    window.open(notionUrls.carrierPortalFAQs, '_blank')
  }

  const renderDropdown = (user: User) => (
    <Transition>
      <Menu.Items className='dropdown !min-w-max'>
        <div className='flex flex-col p-4 pb-2 gap-y-2'>
          <Menu.Item disabled>
            <p className='text-right text-sm uppercase font-bold font-sans text-brand-dark'>
              {user.name}
            </p>
          </Menu.Item>
          <Menu.Item disabled>
            <div className='flex justify-between'>
              <MenuLabel>Company</MenuLabel>
              <MenuData>{companyDetail.name}</MenuData>
            </div>
          </Menu.Item>
          <Menu.Item disabled>
            <div className='flex justify-between'>
              <MenuLabel>{companyIdentifier.label}</MenuLabel>
              <MenuData>{companyIdentifier.value}</MenuData>
            </div>
          </Menu.Item>
          <Menu.Item disabled>
            <div className='flex justify-between'>
              <MenuLabel>Email:</MenuLabel>
              <MenuData className='underline'>{user.email}</MenuData>
            </div>
          </Menu.Item>
          <Menu.Item disabled>
            <div className='flex justify-between'>
              <MenuLabel>Phone Number:</MenuLabel>
              <MenuData>{formatPhone(user.phone) || 'Not Available'}</MenuData>
            </div>
          </Menu.Item>
        </div>
        <div className='flex flex-col p-4 pt-2 pb-1'>
          <Divider />
          <Menu.Item>
            {({ active }) => (
              <MenuButton
                $active={active}
                className='flex justify-start gap-1 mt-1'
                onClick={openFAQ}
              >
                Help
                <ArrowTopRightOnSquareIcon className='w-3 h-3' />
              </MenuButton>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <MenuButton $active={active} type='submit' onClick={broadcastLogout}>
                Sign Out
              </MenuButton>
            )}
          </Menu.Item>
        </div>
      </Menu.Items>
    </Transition>
  )

  const renderLinks = () => (
    <div className='pt-2'>
      <ManagedTransShipperPicker classNames='mr-6' />
      {sections.map((section, i) => (
        <div key={i} className='pb-5'>
          <HeaderText>{section.heading} </HeaderText>
          {section.links.map((link, i) => (
            <NavLink
              key={i}
              state={{ prevPath: location.pathname }}
              className={({ isActive }) =>
                isActive ? 'text-brand-accent font-bold' : 'text-link font-semibold'
              }
              to={{
                pathname: link.url,
              }}
              onClick={() => setMenuOpen(false)}
            >
              <div className='text-base font-sans flex items-center'>
                <div
                  className={`py-4 ${location.pathname.startsWith(link.url) && 'active-link'}`}
                />
                {link?.customLabel ?? link?.label}
              </div>
            </NavLink>
          ))}
        </div>
      ))}
    </div>
  )

  const renderBurger = () => (
    <HamburgerMenu $isOpen={isMenuOpen} id='burger-wrap'>
      <a className='burger' onClick={() => setMenuOpen(!isMenuOpen)}>
        {isMenuOpen ? (
          <XMarkIcon className='text-white w-6 h-10 m-auto' />
        ) : (
          <Bars3Icon className='text-white w-6 h-10 m-auto' />
        )}
      </a>
    </HamburgerMenu>
  )

  if (!user) return null

  return (
    <div className='app-header-component'>
      <Overlay $isDropdownOpen={isDropdownOpen} $isMenuOpen={isMenuOpen} />
      <AppLogoImage className='h-10' color='white' onClick={() => navigate(defaultPath)} />
      {!isProduction && (
        <div className='bg-brand-accent text-brand-dark text-sm font-bold uppercase px-2 py-1 rounded'>
          {import.meta.env.VITE_ENVIRONMENT}
        </div>
      )}
      <div className='flex items-center md:gap-8' data-testid='main-menu'>
        <CarrierCompanyPicker />
        <Menu as='div' className='menu'>
          <div className='flex items-center'>
            <Menu.Button className='hidden md:block avatar-container'>
              <Avatar
                dropdown
                className='text-white text-lg bg-brand-dark hover:bg-gray-600'
                name={user.name}
              />
            </Menu.Button>
            <div className='md:hidden nav-menu'>{renderBurger()}</div>
          </div>
          <MobileMenu $isOpen={isMenuOpen} data-testid='mobile-menu'>
            {isMenuOpen && (
              <div className='overflow-auto'>
                {renderLinks()}
                <div className='mobile-footer'>
                  <Menu.Button className='flex items-center'>
                    <div className='avatar-container'>
                      <Avatar
                        dropdown
                        className='text-white text-lg bg-brand-dark hover:bg-gray-600'
                        name={user.name}
                      />
                    </div>
                  </Menu.Button>
                </div>
              </div>
            )}
          </MobileMenu>
          {renderDropdown(user)}
        </Menu>
      </div>
    </div>
  )
}

const MenuLabel = tw.span`
  text-xs
  text-dark-gray
  pr-4
`

const MenuData = tw.span`
  text-xs
  text-black
`

const Divider = tw.hr`
  mx-[-1rem]
`

const MenuButton = tw.button<{ $active: boolean }>`
  ${({ $active }) => ($active ? 'active' : 'text-gray-700')}
  text-xs
  text-left
  py-2
  px-4
  mx-[-1rem]
  font-semibold
`

const MobileMenu = tw.div<{ $isOpen: boolean }>`
  ${({ $isOpen }) => $isOpen && 'list-open'}
  menu-list
  md:hidden
  overflow-auto
  pl-[24px]
`

const HamburgerMenu = tw.div<{ $isOpen: boolean }>`
  ${({ $isOpen }) => $isOpen && 'open'}
`

const Overlay = tw.div<{ $isDropdownOpen: boolean; $isMenuOpen: boolean }>`
  ${p => (p.$isDropdownOpen || p.$isMenuOpen ? 'opacity-50 h-screen' : 'opacity-0 h-0')}
  overlay
`

const HeaderText = tw.div`
  pb-2
  text-sm
  font-semibold
  font-poppins
`
