import { AppLogoImage } from '@components'
import { useEffect } from 'react'
import tw from 'tailwind-styled-components'

import { notionUrls } from '../../common/constants'
import { trackEvent } from '../../common/tracking'
import { getDocumentTitle } from '../../common/utils'
import { Help } from '../../components/Login'
import { useAppSelector } from '../../redux/store'

export const LoginForm = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const loginStep = useAppSelector(state => state.user.login.currentStep)
  useEffect(() => {
    document.title = getDocumentTitle('Welcome')
  }, [])

  return (
    <Container>
      <CoverImage alt='login banner' src='/login-cover.svg' />
      <FormContainer>
        <InnerWrap>
          <div className='grid justify-items-center mb-2'>
            <AppLogoImage className='h-24 hidden sm:block' variant='icon' />
            <AppLogoImage className='h-24 sm:hidden' />
          </div>
          <p className='text-3xl mb-2 text-brand-dark'>
            {loginStep === 'ONBOARD' ? "Let's get you started" : 'Welcome to our marketplace'}
          </p>
          {children}
          <Help />
          <div className='mt-4 text-center'>
            <a
              className='text-link hover:underline'
              href={notionUrls.privacyPolicy}
              rel='noreferrer'
              target='_blank'
              onClick={() => trackEvent('Clicked login privacy policy link')}
            >
              Privacy Policy
            </a>
          </div>
        </InnerWrap>
      </FormContainer>
    </Container>
  )
}

const Container = tw.div`
block
fixed md:!static
h-screen
md:flex
top-0
w-full
`

const CoverImage = tw.img`
h-0 sm:h-[200px] md:h-full
object-cover
w-full md:w-3/5
`

const FormContainer = tw.div`
absolute md:static
flex
h-full
justify-center
mt-[-20px] sm:mt-[-192px] md:mt-0
self-center
w-full md:w-2/5
`

const InnerWrap = tw.div`
flex
flex-col
justify-center
mx-10 ld:mx-32
text-center
w-full xl:w-4/5 3xl:w-3/5
`
