import { LoaderSpinner } from '@components'
import tw from 'tailwind-styled-components'

import { broadcastLogin } from '../../../../components/src/common/utils'
import { useAppSelector, useAppThunkDispatch } from '../../redux/store'
import { getUserToken } from '../../redux/userSlice'

export const CarrierCompany = ({
  company,
  authenticate = false,
  popover = false,
}: {
  company: { id: number; name: string; mcNumber: string; dotNumber: string }
  authenticate?: boolean
  popover?: boolean
}) => {
  const dispatch = useAppThunkDispatch()
  const loading = useAppSelector(state => state.user.loading.getUserToken)
  const companyId = useAppSelector(state => state.user.companyId)

  const selectCompany = async () => {
    if (companyId === company.id) return
    const response = await dispatch(getUserToken(company.id))
    if (response.meta.requestStatus === 'rejected' || !authenticate) return
    broadcastLogin()
  }

  return (
    <CompanyContainer
      $highlighted={companyId === company.id}
      $popover={popover}
      onClick={() => selectCompany()}
    >
      <div className='flex flex-col grow font-semibold'>
        <div className='flex justify-between'>
          <span className='text-left'>{company.name}</span>
          {loading === company.id && <LoaderSpinner className='my-0' size={4} />}
        </div>
        <div className='flex justify-between text-[0.7rem] text-slate-600'>
          {company.mcNumber && <span>MC: {company.mcNumber}</span>}
          {company.dotNumber && <span>DOT: {company.dotNumber}</span>}
        </div>
      </div>
    </CompanyContainer>
  )
}

const CompanyContainer = tw.div<{ $popover?: boolean; $highlighted: boolean }>`
  rounded-lg
  p-3
  w-full
  flex
  text-sm
  cursor-pointer
  hover:bg-lighter-blue
  transition-all
  justify-between
  h-14
  ${({ $popover }) => !$popover && `border border-light-gray`}
  ${({ $highlighted }) => $highlighted && 'bg-light-blue'}
`
