import axios from 'axios'
import axiosBetterStacktrace from 'axios-better-stacktrace'

import { getClientId } from '../common/utils'

axiosBetterStacktrace(axios)

// for local, we use a proxy, so we don't need to set the base url
// axios.defaults.baseURL = 'http://192.168.1.173:8000/'
axios.defaults.baseURL = import.meta.env.VITE_BASE_URL
// vitest (test runner) doesn't like top-level invocations
axios.defaults.headers.common['X-Client-Id'] = import.meta.env.VITEST ? 1 : getClientId()
axios.defaults.withCredentials = true
axios.defaults.withXSRFToken = true
axios.defaults.xsrfCookieName = 'csrftoken3'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

export { axios as api }
